import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import axios from "axios";
import {
  Cascader,
  Row,
  List,
  Col,
  Input,
  Form,
  Button,
  Typography,
  Table,
  Space,
  Card,
  Tooltip,
  Modal,
  Pagination,
  Select,
  Divider,
  DatePicker,
} from "antd";
import Loader from "react-loader-spinner";
// @ts-ignore
import {
  NotificationContainer,
  NotificationManager,
  // @ts-ignore
} from "react-notifications";
import "react-notifications/lib/notifications.css";
type GetQuotaComponentProps = {
  surveys: any;
};
const GetQuota: React.FC<GetQuotaComponentProps> = (props) => {
  var FileSaver = require("file-saver");
  const [surveys, setSurveys] = useState<any>([]);
  const [surveydd, setSurveydd] = useState<any>([]);
  const [selectedSurvey, setSelectedSurvey] = useState<any>();
  const [selectedRecalcSurvey, setSelectedRecalcSurvey] = useState<any>();
  const [
    selectedQuotaResetSurvey,
    setSelectedQuotaResetSurvey,
  ] = useState<any>();
  const [questions, setQuestions] = useState<any>([]);
  const [quotasToReset, setQuotasToReset] = useState<any>([]);
  const API_URL = process.env.REACT_APP_API_URL;
  const [loadingtab, setLoadingtab] = useState(false);
  const [surveyQuota, setSurveyQuota] = useState([]);
  const [historicalSurvey, setHistoricalSurvey] = useState<any>();
  const [historicalIntervals, setHistoricalIntervals] = useState<any>([]);
  const [selectedDate, setSelectedDate] = useState<any>();
  const [selectedIntervals, setSelectedIntervals] = useState<any>([]);
  const { RangePicker } = DatePicker;
  useEffect(() => {
    const fetchSurvey = async () => {
      setSurveys(props.surveys);
      if (
        props.surveys &&
        props.surveys.data &&
        props.surveys.data.length > 0
      ) {
        setSurveydd(
          props.surveys.data.map((survey: any) => {
            return { value: survey.id, label: survey.name };
          })
        );
      }
    };
    fetchSurvey();
  }, [props.surveys]);
  function handleSurveyChange(value: any) {
    surveys.data.map((survey: any) => {
      if (survey.id === value) {
        setSelectedSurvey(survey);
      }
    });
  }
  function handleRecalcSurveyChange(value: any) {
    surveys.data.map((survey: any) => {
      if (survey.id === value) {
        let tmpSurvey = {...survey};
        switch(tmpSurvey.type) {
          case 'adhoc':
            delete tmpSurvey.type;
            delete tmpSurvey.startOfWeek;
            break;
          case 'week_of_month_tracker':
            tmpSurvey.type = 'weekmonth';
            break;
          case 'weekly_tracker':
            tmpSurvey.type = 'week';
            break;
          case 'daily_tracker':
            tmpSurvey.type = 'date';
            break;
          case 'quarterly_tracker':
            tmpSurvey.type = 'quarter';
            // console.log ('*******************************',tmpSurvey.type)
            break;
            case 'yearly_tracker':
              tmpSurvey.type = 'year';
              break;
          default:
        }
        setSelectedRecalcSurvey(tmpSurvey);
      }
    });
  }
  async function handleQuotaResetSurveyChange(value: any) {
    console.log(value);
    surveys.data.map((survey: any) => {
      if (survey.id === value) {
        setSelectedQuotaResetSurvey(survey);
      }
    });
    await axios
      .get(API_URL + `/internaltool/getSurveyQuota?surveyId=${value}`)
      .then((response) => {
        console.log(response.data);
        const tempSurveyQuota: any = [];
        if (response.data.rules) {
          for (let i = 0; i < response.data.rules.length; i++) {
            tempSurveyQuota.push({
              value: response.data.rules[i].id,
              label: response.data.rules[i].name,
            });
          }
        }
        setSurveyQuota(tempSurveyQuota);
      });
  }
  async function handleQuotasChange(value: any, id: any) {
    setQuotasToReset(value);
  }
  async function exportQuota() {
    setLoadingtab(true);
    try {
      const requestBody = {
        surveyId: selectedSurvey.id,
        startOfWeek: selectedSurvey.startOfWeek,
        type: selectedSurvey.type,
      };

      try {
        await axios
          .post(API_URL + "/internaltool/getQuota", requestBody, {
            responseType: "arraybuffer",
          })
          .then((response) => {
            console.log(response);
            var blob = new Blob([response.data], {
              type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            FileSaver.saveAs(
              blob,
              `quotas_${selectedSurvey.id}_${new Date()
                .toISOString()
                .replace(/T/, " ")
                .replace(/\..+/, "")}`
            );
          });
        setLoadingtab(false);
        NotificationManager.success(
          "File Downloaded Succesfully",
          "Success !!",
          3000
        );
      } catch (err) {
        setLoadingtab(false);
        NotificationManager.error("Please try again", "Error !!", 3000);
      }
    } catch (err) {
      setLoadingtab(false);
      NotificationManager.error("Please Try again", "Error !!");
    }
  }
  async function recalcQuota() {
    setLoadingtab(true);
    console.log(selectedDate,"selectedDate");
    
    try {
      const requestBody = {
        surveyId: selectedRecalcSurvey.id,
        startOfWeek: selectedRecalcSurvey.startOfWeek,
        type: selectedRecalcSurvey.type,
        startDate: selectedDate?.startDate,
        endDate: selectedDate?.endDate,

      };
      await axios
        .post(API_URL + "/internaltool/recalcQuota", requestBody)
        .then((response) => {
          setLoadingtab(false);
          NotificationManager.success(
            "Quota Reset Succesfully",
            "Success !!",
            3000
          );
        });
      console.log(requestBody);
    } catch (err) {
      setLoadingtab(false);
      NotificationManager.error("Please try again", "Error !!", 3000);
    }
  }
  async function resetQuota() {
    setLoadingtab(true);
    try {
      const requestBody = {
        quotas: quotasToReset,
      };
      await axios
        .post(API_URL + "/internaltool/resetQuota", requestBody)
        .then((response) => {
          setLoadingtab(false);
          NotificationManager.success(
            "Quota Reset Succesfully",
            "Success !!",
            3000
          );
        });
      console.log(requestBody);
    } catch (err) {
      setLoadingtab(false);
      NotificationManager.error("Please try again", "Error !!", 3000);
    }
  }

  function handleSurveyHistoricalChange(value: any) {
    setHistoricalSurvey(value);
    setHistoricalIntervals([]);
    setSelectedIntervals([]);

    surveys.data.map((survey: any) => {
      if (survey.id === value) {
        // console.log(survey, "IS SELECTED")
        if (survey.type !== "adhoc") {
          const body = {
            id: survey.id,
            type: survey.type,
            startOfWeek: survey.startOfWeek,
          };
          axios
            .post(API_URL + `/internaltool/quota-intervals`, body)
            .then((response) => {
              // console.log(response)
              setHistoricalIntervals(response.data);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    });
  }

  function handleIntervalChange(value: any) {
    // console.log("Selected Interval ", value)
    const interv: any = [];
    value.map((val: any) => {
      const chosenInterval = historicalIntervals.find(
        (interv: any, index: Number) => index == val
      );
      console.log(chosenInterval);
      interv.push(chosenInterval);
    });
    setSelectedIntervals(interv);
  }
  function getExcel() {
    const surveyName = surveys.data.find(
      (survey: any) => survey.id == historicalSurvey
    );
    setLoadingtab(true);
    axios
      .post(
        API_URL + `/internaltool/quota-excel`,
        {
          surveyId: historicalSurvey,
          surveyName,
          dates:
            selectedDate &&
            selectedDate?.startDate !== "" &&
            selectedDate?.endDate !== ""
              ? [selectedDate]
              : selectedIntervals,
          choseDates: selectedDate ? true : false,
        },
        { responseType: "arraybuffer", timeout: 1800000 }
      )
      .then((response) => {
        setLoadingtab(false);
        var blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        FileSaver.saveAs(
          blob,
          `quotas_${surveyName.name}_${new Date()
            .toISOString()
            .replace(/T/, " ")
            .replace(/\..+/, "")}`
        );
        NotificationManager.success(
          "File Downloaded Succesfully",
          "Success !!",
          3000
        );
      })
      .catch((err) => {
        setLoadingtab(false);
        if (err.response.status == 300) {
          NotificationManager.error(
            "",
            "No Quotas defined for this survey !!",
            3000
          );
        } else {
          NotificationManager.error("Please try again", "Error !!", 3000);
        }
      });
  }
  return (
    <Form
      style={{
        alignItems: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <Row>
        <Col span={11}>
          <Divider style={{ width: "50%" }} plain type="horizontal">
            <h1>GET QUOTAS</h1>
          </Divider>
          <Row style={{ width: "100%", marginBottom: 15 }}>
            <Col span={2}>Survey:</Col>
            <Col span={8}>
              <Select
                showSearch
                dropdownMatchSelectWidth={false}
                allowClear
                style={{ width: "100%" }}
                options={surveydd}
                placeholder="Please Select Survey"
                onChange={handleSurveyChange}
                filterOption={(input, option) => {return (option?.label as string)?.toLowerCase().indexOf(input.toLowerCase()) >= 0}}
                optionFilterProp="children"
              ></Select>
            </Col>
          </Row>
          <Row style={{ width: "100%", marginBottom: 15 }}>
            <Button
              disabled={loadingtab || !selectedSurvey}
              style={{ float: "right", marginBottom: "-3%" }}
              onClick={() => {
                exportQuota();
              }}
            >
              Submit
            </Button>
          </Row>
        </Col>
        <Col style={{ height: "200px" }}>
          <Divider type="vertical" style={{ height: "100%" }} />
        </Col>
        <Col span={11}>
          <Divider plain type="horizontal">
            <h1>RESET QUOTAS</h1>
          </Divider>
          <Row style={{ width: "100%", marginBottom: 15 }}>
            <Col span={2}>Survey:</Col>
            <Col span={8}>
              <Select
                showSearch
                dropdownMatchSelectWidth={false}
                allowClear
                style={{ width: "100%" }}
                options={surveydd}
                placeholder="Please Select Survey"
                onChange={handleQuotaResetSurveyChange}
                filterOption={(input, option) => {return (option?.label as string)?.toLowerCase().indexOf(input.toLowerCase()) >= 0}}
                optionFilterProp="children"
              ></Select>
            </Col>
          </Row>
          <Row style={{ width: "100%", marginBottom: 15 }}>
            <Col span={2}>Quotas:</Col>
            <Col span={8}>
              <Select
                showSearch
                dropdownMatchSelectWidth={false}
                allowClear
                labelInValue
                mode="multiple"
                style={{ width: "100%" }}
                options={surveyQuota}
                placeholder="Please Select Quota"
                onChange={handleQuotasChange}
                filterOption={(input, option) => {return (option?.label as string)?.toLowerCase().indexOf(input.toLowerCase()) >= 0}}
                optionFilterProp="children"
              ></Select>
            </Col>
          </Row>
          <Row style={{ width: "100%", marginBottom: 15 }}>
            <Button
              disabled={
                loadingtab ||
                !quotasToReset ||
                (quotasToReset && quotasToReset.length === 0)
              }
              style={{ float: "right", marginBottom: "-3%" }}
              onClick={() => {
                resetQuota();
              }}
            >
              Reset
            </Button>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col span={11}>
          <Divider style={{ width: "50%" }} plain type="horizontal">
            <h1>GET HISTORICAL QUOTA</h1>
          </Divider>
          <Row style={{ width: "100%", marginBottom: 15 }}>
            <Col span={5}>Survey:</Col>
            <Col span={19}>
              <Select
                showSearch
                dropdownMatchSelectWidth={false}
                allowClear
                style={{ width: "100%" }}
                options={surveydd}
                placeholder="Please Select Survey"
                onChange={handleSurveyHistoricalChange}
                filterOption={(input, option) => {return (option?.label as string)?.toLowerCase().indexOf(input.toLowerCase()) >= 0}}
                optionFilterProp="children"
              ></Select>
            </Col>
          </Row>
          <Row style={{ width: "100%", marginBottom: 15 }}>
            <Col span={5}>Interval:</Col>
            <Col span={19}>
              <RangePicker
                style={{ width: "100%" }}
                format="YYYY-MM-DD 00:00:00"
                onCalendarChange={(date: any, dateString: any) => {
                  setSelectedDate({
                    startDate: dateString[0],
                    endDate: dateString[1],
                  });
                }}
                showTime={false}
                // showTime={{
                //   hideDisabledOptions: true,
                //   defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('00:00:00', 'HH:mm:ss')],
                // }}
              />
            </Col>
          </Row>
          <Row
            style={{
              margin: "5px auto",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Col span={2}>Or</Col>
          </Row>
          <Row style={{ width: "100%", marginBottom: 15 }}>
            <Col span={5}>Custom Interval:</Col>
            <Col span={19}>
              <Select
                mode="multiple"
                dropdownMatchSelectWidth={false}
                value={selectedIntervals.map((interv: any, index: Number) => {
                  return historicalIntervals.findIndex(
                    (interval: any) => interval == interv
                  );
                })}
                allowClear
                style={{ width: "100%" }}
                options={historicalIntervals.map(
                  (interv: any, index: Number) => {
                    return {
                      value: index,
                      label: interv.customInterval,
                    };
                  }
                )}
                placeholder="Please Select Survey"
                onChange={handleIntervalChange}
              ></Select>
            </Col>
          </Row>

          <Row style={{ width: "100%", marginBottom: 15 }}>
            <Button
              disabled={!historicalSurvey}
              style={{ float: "right", marginBottom: "-3%" }}
              onClick={() => {
                getExcel();
              }}
            >
              Submit
            </Button>
          </Row>
        </Col>
        <Col style={{ height: "200px" }}>
          <Divider type="vertical" style={{ height: "100%" }} />
        </Col>
        <Col span={11}>
          <Divider plain type="horizontal">
            <h1>RECALCULATE QUOTAS</h1>
          </Divider>
          <Row style={{ width: "100%", marginBottom: 15 }}>
            <Col span={2}>Survey:</Col>
            <Col span={8}>
              <Select
                showSearch
                dropdownMatchSelectWidth={false}
                allowClear
                style={{ width: "100%" }}
                options={surveydd}
                placeholder="Please Select Survey"
                onChange={handleRecalcSurveyChange}
                filterOption={(input, option) => {return (option?.label as string)?.toLowerCase().indexOf(input.toLowerCase()) >= 0}}
                optionFilterProp="children"
              ></Select>
                </Col>
          </Row>
                 <Row style={{ width: "100%", marginBottom: 15 }}>
            <Col span={2}>Interval:</Col>
            <Col span={8}>
              <RangePicker
                style={{ width: "100%" }}
                format="YYYY-MM-DD 00:00:00"
                onCalendarChange={(date: any, dateString: any) => {
                  setSelectedDate({
                    startDate: dateString[0],
                    endDate: dateString[1],
                  });
                }}
                showTime={false}
                // showTime={{
                //   hideDisabledOptions: true,
                //   defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('00:00:00', 'HH:mm:ss')],
                // }}
              />
               </Col>
               </Row>

          <Row style={{ width: "100%", marginBottom: 15 }}>
              <Button
                disabled={loadingtab || !selectedRecalcSurvey}
                style={{ float: "right", marginBottom: "-3%" }}
                onClick={() => {
                  recalcQuota();
                }}
              >
                Submit
              </Button>
              </Row>
           
        </Col>
      </Row>

      {loadingtab && (
        <Loader
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
          type="BallTriangle"
          color="#00BFFF"
          height="120"
          width="80"
        />
      )}
      <NotificationContainer />
    </Form>
  );
};
export default GetQuota;
